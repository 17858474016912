<template>
    <AdminToast />
    <confirm-dialog></confirm-dialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='employee'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Ödeme Bilgileri</h3>

					<p class='text-lg'>
                        Ödeme bilgilerini değiştirdiğinizde yetkililere sms gidecektir. Onay almadan maaş bilgilerini değiştirmeyin.<br>
                        <br>
                        <b>Maaş:</b> Bu bilgiye sahip değilseniz muhasebe ile irtibata geçiniz.<br>
                        <br>
                        <b>Diğer Maaş:</b> Bu bilgiye sahip değilseniz muhasebe ile irtibata geçiniz.<br>
                        <br>
                        <b>Banka:</b> Personelin maaşını almak istediği bankayı seçiniz.<br>
                        <br>
                        <b>IBAN:</b> Personelin beyan ettiği IBAN numarasını giriniz.<br>
					</p>
				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>
                <project-employee-tab-menu :employee-id='employeeId'
                                           :project-employee-id='projectEmployeeId'></project-employee-tab-menu>
                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Maaş<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputNumber id='officialSalary' autocomplete='off'
                                         v-model='projectEmployee.officialSalary' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Diğer Maaş<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputNumber id='unOfficialSalary' autocomplete='off'
                                         v-model='projectEmployee.unOfficialSalary' type='text' />
                        </div>
                    </div>
                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Toplam Maaş</label>
                        <div class='col-12 md:col-10'>
                           {{ totalSalary }}
                        </div>
                    </div>
                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button v-if='visibleSaveButton' id='save' label='Kaydet' icon='pi pi-save' @click='saveConfirmation'></Button>
                        </div>
                    </div>

                    <hr class='mt-5 mb-5'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Banka<span class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='banka' v-model='projectEmployee.bankName' :options='bankList'
                                      optionLabel='name' option-value='name' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>IBAN<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='iban' autocomplete='off' v-model='projectEmployee.iban' type='text'
                                       maxlength='26' />
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveBankInformation' label='Kaydet' icon='pi pi-save'
                                    @click='saveBankInformation'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage, showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import moment from 'moment';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import { getAllBanks, getEmployee, getProjectEmployee } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid, getActiveProjectId, getUserId } from '../common/commonConstantFunctions';
import SmsService from '@/services/smsService';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _projectEmployeeService: null,
    _smsService: null,

    computed: {
        totalSalary(){
            return this.formatPrice(this.projectEmployee.officialSalary + this.projectEmployee.unOfficialSalary) + " TL";
        }
    },
    created() {
        this._smsService = new SmsService();
        this._employeeService = new EmployeeService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
        this.projectId = getActiveProjectId();
        this._projectEmployeeService = new ProjectEmployeeService();
    },
    async mounted() {
        this.employee = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        this.bankList = await getAllBanks();
        this.oldOfficialSalary = this.projectEmployee.officialSalary;
        this.oldUnOfficialSalary = this.projectEmployee.unOfficialSalary;
        this.officialHourlySalary = Math.floor(this.projectEmployee.officialSalary / 30 / 8);
        this.unOfficialHourlySalary = Math.floor(this.projectEmployee.unOfficialSalary / 30 / 8);
    },
    data() {

        return {
            oldOfficialSalary: 0,
            oldUnOfficialSalary: 0,
            employeeId: 0,
            projectEmployeeId: 0,
            officialHourlySalary: 0,
            unOfficialHourlySalary: 0,
            projectEmployee: {
                projectId: 0,
                startDate: '',
                department: '',
                officialSalary: 0,
                unOfficialSalary: 0,
                insuranceDeposit: 0,
                bankName: '',
                iban: '',
                employeeId: 0,
                isSalaryChanged: false,
            },
            employee: {},
            bankList: [],
            display: false,
            visibleLeft: false,
            visibleSaveButton: true,
        };
    },
    methods: {

        async saveConfirmation() {
            if(this.oldOfficialSalary == this.projectEmployee.officialSalary && this.oldUnOfficialSalary == this.projectEmployee.unOfficialSalary)
            {
                showValidationMessage(this,"Maaş değişikliği yapmamışsınız");
                return;
            }

            this.$confirm.require({
                message: 'Yaptığınız Maaş Değişikliği Yönetici Onayına Gönderilecektir. Yönetici Onaylayana Kadar Personel İle Avans, İzin, Mesai veya Başka İşlem Yapamayacaksınız. Devam Etmek İstiyor Musunuz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    await this.save();
                },
            });
        },
        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._projectEmployeeService.updateProjectEmployee(this.projectEmployeeId, this.projectEmployee);
                if (updateResponse.isSuccess) {
                    await this.addSalaryChangeLog();
                    await this._smsService.sendEmployeeNewSalaryMessage(this.projectEmployeeId);
                    showSuccessMessage(this, 'Çalışan Güncelleme Başarılı');
                    this.visibleSaveButton = false;
                } else {
                    showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
                }
            }
        },

        async saveBankInformation() {

            if (this.validateBankInformationForm()) {
                const updateResponse = await this._projectEmployeeService.updateProjectEmployeeBankInformation(this.projectEmployeeId, this.projectEmployee);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Çalışan Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
                }
            }
        },

        validateBankInformationForm() {
            if (this.projectEmployee.iban == null || this.projectEmployee.iban === '') {
                showValidationMessage(this, 'IBAN Alanı Boş Bırakılamaz.');
                return false;
            }

            if (this.projectEmployee.bankName == null || this.projectEmployee.bankName === '') {
                showValidationMessage(this, 'Banka Alanı Boş Bırakılamaz.');
                return false;
            }

            return true;
        },
        validateForm() {

            if (this.projectEmployee.isActive == false) {
                showValidationMessage(this, 'İşten Çıkarılmış Personelin Maaş Bilgileri Değiştirilemez.');
                return false;
            }

            if (this.projectEmployee.isSalaryChanged == true) {
                showValidationMessage(this, 'Son Yapılan Maaş Değişikliği Onaylanmadığı İçin Değişiklik Yapamazsınız');
                return false;
            }


            if (this.projectEmployee.unOfficialSalary == null || this.projectEmployee.unOfficialSalary === '') {
                showValidationMessage(this, 'Diğer Maaş Alanı Boş Bırakılamaz.');
                return false;
            }


            return true;
        },

        async addSalaryChangeLog() {
            let salaryLog = {
                projectEmployeeId: this.projectEmployeeId,
                managerUserId: getUserId(),
                officialSalary: this.projectEmployee.officialSalary,
                unOfficialSalary: this.projectEmployee.unOfficialSalary,
                bankName: this.projectEmployee.bankName,
                iban: this.projectEmployee.iban,
                oldUnOfficialSalary : this.oldUnOfficialSalary,
                oldOfficialSalary : this.oldOfficialSalary
            };
            await this._projectEmployeeService.insertEmployeeSalaryChange(salaryLog);
        },


        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    },
};

</script>


<style scoped>

</style>
